<template>
  <div ref="getParentSize">
    <v-app>
      <v-navigation-drawer
        app
        v-model="drawer"
        :mini-variant.sync="mini"
        :mini-variant-width="0"
        :width="220"
        permanent
        stateless
      >
        <v-list-item>
          <v-list-item-title>
            <v-img
              alt="AirTally Logo"
              contain
              min-width="40"
              src="../assets/AirTally_logo_Rlarge_White.png"
              width="80"
            />
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>マップ設定</v-list-item-title>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>表示子機選択</v-list-item-title>
              <v-list dense>
                <v-list-item v-for="(cam, index) in visibleCameraSettings" :key="cam.deviceID">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="visibleCameraSettings[index].visible"
                      @change="changeVisibleCameraSettings(visibleCameraSettings[index])"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ cam.deviceName }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-radio-group v-model="centerSetting">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>表示位置・倍率設定</v-list-item-title>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-action>
                      <v-radio value="auto"></v-radio>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>自動</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-radio value="manual"></v-radio>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>手動</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
          <v-divider></v-divider>
          <v-list-item to="/">
            <v-list-item-title><v-icon>mdi-home</v-icon> Home </v-list-item-title>
          </v-list-item>
          <v-list-item to="/assign" v-if="group === 'admin' || group === 'user'">
            <v-list-item-title><v-icon>mdi-dip-switch</v-icon> タリー割当設定 </v-list-item-title>
          </v-list-item>
          <v-list-item to="/camerasetting" v-if="group === 'admin' || group === 'user'">
            <v-list-item-title><v-icon>mdi-video-wireless-outline</v-icon> 子機設定 </v-list-item-title>
          </v-list-item>
          <v-list-item to="/device" v-if="group === 'admin' || group === 'user'">
            <v-list-item-title><v-icon>mdi-view-list-outline</v-icon> デバイス一覧 </v-list-item-title>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item class="d-flex justify-center">
                <amplify-sign-out />
              </v-list-item>
              <v-list-item-subtitle class=".text-caption">
                &copy;2021 RATOC Systems,Inc./ABC-TV
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-navigation-drawer>
      <v-main>
        <GoogleMap
          ref="googlemap"
          v-on:toggleDrawer="mini = !mini"
          :visibleCameraSettings="visibleCameraSettings"
          :Cameras="Cameras"
          :centerSetting="centerSetting"
        />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import API, { graphqlOperation } from "@aws-amplify/api";
import GoogleMap from "./GoogleMap.vue";
import { listDevices } from "../graphql/queries";

export default {
  components: {
    GoogleMap,
  },
  data() {
    return {
      drawer: true,
      mini: true,
      Switchers: [],
      Cameras: [],
      visibleCameraSettings: [],
      active: "",
      centerSetting: "auto",
      zoomSetting: "auto",
      group: "",
    };
  },
  mounted() {},
  methods: {
    initMap() {
      this.$refs.googlemap.initGetPos();
    },
    changeVisibleCameraSettings(item) {
      console.log("log", item);
      this.$refs.googlemap.changeVisibleCamera();
    },
    async fetch() {
      const items = await API.graphql(graphqlOperation(listDevices)).catch((err) =>
        console.error("listDevices", err)
      );
      this.devices = items.data.listDevices.items;
      console.log("this.devices", this.devices);

      //switcherとcameraの振り分け
      for (var deviceIndex in this.devices) {
        console.log(deviceIndex);
        if (this.devices[deviceIndex].deviceType === "switcher") {
          this.Switchers.push(this.devices[deviceIndex]);
          console.log("this.Switchers", this.Switchers);
        }

        if (this.devices[deviceIndex].deviceType === "camera") {
          this.Cameras.push(this.devices[deviceIndex]);
          console.log("this.Cameras", this.Cameras);
        }
      }

      this.ObjArraySort(this.Switchers, "deviceName");
      this.ObjArraySort(this.Cameras, "deviceName");

      console.log("Switchers", this.Switchers);
      console.log("Cameras", this.Cameras);

      for (var cameraIndex in this.Cameras) {
        this.visibleCameraSettings.push({
          deviceID: this.Cameras[cameraIndex].deviceID,
          deviceName: this.Cameras[cameraIndex].deviceName,
          description: this.Cameras[cameraIndex].description,
          visible: true,
        });
      }
      console.log(this.visibleCameraSettings);
      this.initMap();
    },
    ObjArraySort(ary, key, order) {
      var reverse = 1;
      if (order && order.toLowerCase() == "desc") reverse = -1;
      ary.sort(function(a, b) {
        if (a[key] < b[key]) return -1 * reverse;
        else if (a[key] == b[key]) return 0;
        else return 1 * reverse;
      });
    },
  },
  async created() {
    this.user = await Auth.currentAuthenticatedUser();
    this.group = await this.user.signInUserSession.accessToken.payload['cognito:groups'][0];
    this.fetch();
  },
  beforeDestroy() {},
};
</script>
